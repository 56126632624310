export const ROUTER_PATH = {
  home: "/",
  login: "/login",
  settingProduct: "/initial-setting/product",
  settingCompetitors: (productId: number) =>
    `/initial-setting/competitors/${productId}`,
  settingTargetAudience: (productId: number) =>
    `/initial-setting/target-audience/${productId}`,
  comparison: "/comparison",
  competitors: "/competitors",
  editPage: ({
    productId,
    competitorId,
    targetId,
    pageId,
  }: {
    productId: number;
    competitorId: number;
    targetId: number;
    pageId: number;
  }) => `/page/${productId}/${competitorId}/${targetId}/${pageId}`,
  initialPageDesignSetting: ({
    productId,
    competitorId,
    targetId,
    pageId,
  }: {
    productId: number;
    competitorId: number;
    targetId: number;
    pageId: number;
  }) =>
    `/initial-design-setting/product/${productId}/${competitorId}/${targetId}/${pageId}`,
  figmaRoot: "/figma",
  figmaLogin: "/figma/login",
  figmaLoginRedirect: "/figma/login/redirect",
  figmaLoginLoading: "/figma/login/loading",
  figmaDomains: "/figma/domains",
  figmaPages: (productId: number) => `/figma/pages/${productId}`,
  figmaSignUp: "/figma/sign-up",
} as const;
