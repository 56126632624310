"use client";

import { Button, Icon } from "@landwich/ds";
import { useRouter } from "next/navigation";
import { ROUTER_PATH } from "@/shared/@common/constants/router";

const NotFound = () => {
  const router = useRouter();

  return (
    <main className="flex justify-center items-center flex-col gap-4 w-screen h-screen">
      <Icon color="charcoal_500" icon="ph:spinner" size={64} />
      <div className="flex flex-col gap-2 items-center justify-center">
        <h1 className="text-lg-semibold text-charcoal_500">Page not found</h1>
        <div className="text-md-medium text-charcoal_300">404 Error</div>
      </div>
      <Button
        className="w-[200px]"
        color="black"
        onClick={() => {
          router.push(ROUTER_PATH.comparison);
        }}
        paddingX={8}
        paddingY={4}
        textVariant="text-lg-medium"
      >
        Go to Main
      </Button>
    </main>
  );
};

export default NotFound;
